import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Make', 'blueflag_make', false, 'desc', 'left'),
  new TableColumn('Model', 'blueflag_model', false, 'desc', 'left'),
  new TableColumn('Body', 'body_type', false, 'desc', 'left'),
  new TableColumn('Colour', 'colour', false, 'desc', 'left'),
  new TableColumn('Engine No.', 'engine_number', false, 'desc', 'left'),
  new TableColumn('Type', 'vehicle_type', false, 'desc', 'left'),
  new TableColumn('Build Year', 'year_of_manufacture', false, 'desc', 'left')
]

const FactoryColumns = [
  new TableColumn('Make', 'make', false, 'desc', 'left'),
  new TableColumn('Model', 'model', false, 'desc', 'left'),
  new TableColumn('Series', 'series', false, 'desc', 'left'),
  new TableColumn('Variant', 'variant', false, 'desc', 'left'),
  new TableColumn('Body', 'body', false, 'desc', 'left'),
  new TableColumn('Colour', 'colour', false, 'desc', 'left'),
  new TableColumn('Engine No.', 'engine_number', false, 'desc', 'left'),
  new TableColumn('Engine Size', 'litres', false, 'desc', 'left'),
  new TableColumn('Transmission', 'transmission', false, 'desc', 'left'),
  new TableColumn('Type', 'vehicle_type', false, 'desc', 'left'),
  new TableColumn('Build Year', 'buildYear', false, 'desc', 'left')
]

export { Columns, FactoryColumns }
