import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'

export default {
  async getStateListComboProto() {
    const result = await ProtoBufApi('/protobuf/country/states/listcombo')
    const stateProto = require('../../assets/proto/listcombo/States.proto')
    const protoMessage = stateProto.State.StateListComboModel
    const entity = protoMessage.decode(new Uint8Array(result.data))
    return entity.states
  },
  async getCompanyDefaultAddress() {
    const url = '/companies/defaultaddress'
    const result = await FqApi.get(url)
    return result.data
  },
  async searchVehicle(calllog) {
    const url = '/vehiclelookup/searchbyrego'
    const result = await FqApi.post(url, calllog)
    return result
  },
  async getActiveSubscription() {
    const url = '/vehiclelookup/subscription/active'
    const result = await FqApi.get(url)
    return result.data
  },
  async getPricing(tier) {
    const url = `/vehiclelookup/pricing/${tier}`
    const result = await FqApi.get(url)
    return result.data
  },
  async saveVehicle(vehicle) {
    const url = '/vehiclelookup/savevehicle'
    const result = await FqApi.post(url, vehicle)
    return result.data
  }
}