<template>
  <div v-if="value"
    class="tile is-child box detail-page-tile">
    <bulma-table v-if="hasFactory"
      class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :show-pagination="false">
      <tr v-for="(v, index) in value"
        :key="index"
        :class="{ 'is-selected' : selectedRow === index }"
        @click="highlightSelected(index, $event)">
        <td>{{ v.factory.make }}</td>
        <td>{{ v.factory.model }}</td>
        <td>{{ v.factory.series }}</td>
        <td>{{ v.factory.variant }}</td>
        <td>{{ v.factory.body }}</td>
        <td>{{ v.colour }}</td>
        <td>{{ v.engine_number }}</td>
        <td>{{ v.factory.litres }}</td>
        <td>{{ v.factory.transmission }}</td>
        <td>{{ v.vehicle_type }}</td>
        <td>{{ v.factory.buildYear }}</td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
    </bulma-table>
    <bulma-table v-else
      class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :show-pagination="false">
      <tr v-for="(v, index) in value"
        :key="index"
        :class="{ 'is-selected' : selectedRow === index }"
        @click="highlightSelected(index, $event)">
        <td>{{ v.blueflag_make }}</td>
        <td>{{ v.blueflag_model }}</td>
        <td>{{ v.body_type }}</td>
        <td>{{ v.colour }}</td>
        <td>{{ v.engine_number }}</td>
        <td>{{ v.vehicle_type }}</td>
        <td>{{ v.year_of_manufacture }}</td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
    </bulma-table>
  </div>
</template>

<script>
import { Columns, FactoryColumns } from './columns'
import BulmaTable from '@/components/BulmaTable'

export default {
  name: 'VehicleLookupResult',
  components: {
    BulmaTable
  },
  mixins: [],
  props: {
    value: null
  },
  data: () => {
    return {
      selectedRow: null,
      totalRows: 0,
      isTableLoading: false
    }
  },
  computed: {
    columns() {
      return this.hasFactory ? FactoryColumns : Columns
    },
    hasFactory() {
      return this.value && this.value.length > 0 && this.value[0].factory
    }
  },
  watch: {
    value(newVal) {
      this.totalRows = this.value ? this.value.length : 0
      if (this.totalRows === 1) {
        this.highlightSelected(0)
      }
    }
  },
  created() {
    this.totalRows = this.value ? this.value.length : 0
    if (this.totalRows === 1) {
      this.selectedRow = 0
    }
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
      this.$emit('selected', this.value[index])
    }
  }
}
</script>